/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import useIntlMessage from '@/hooks/use-intl-message';
// import { Icon } from 'react-icons-kit';
// import { ic_view_list } from 'react-icons-kit/md/ic_view_list';
import { StyledNavbar, StyledMobileNav } from '@/styles/index';
import { DrawerProvider } from '@/contexts/nav-drawer-context';
import useWindowSize from '@/hooks/use-window-size';
// import Tippy from '@/components/Tooltip';
import { Icon } from 'react-icons-kit';
import { userPlus } from 'react-icons-kit/fa/userPlus';
import { userO } from 'react-icons-kit/fa/userO';
import { ic_add } from 'react-icons-kit/md/ic_add';
import { ic_menu } from 'react-icons-kit/md/ic_menu';
import AccountAvatar from '@/components/Layout/Navbar/AccountAvatar';

const Navbar = ({ session }) => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const { width } = useWindowSize();
  // console.log({ width });
  const { intlMessage: m } = useIntlMessage();

  useEffect(() => {
    if (width > 767) {
      if (mobileMenu) {
        setMobileMenu(false);
      }
    }
  }, [width, mobileMenu]);

  return (
    <DrawerProvider>
      <StyledNavbar>
        <div className="nav-start">
          <Link href="/">
            <a className="nav-logo">
              <Image src="/imgs/pollme_logo.png" alt="pollme nav-logo" layout="fill" />
            </a>
          </Link>
        </div>
        <div className="nav-items">
          <div className="nav-links">
            <Link href="/features">
              <a className="nav-item">{m({ id: 'features', route: 'layout.navbar' })}</a>
            </Link>
            <Link href="/premium">
              <a className="nav-item">{m({ id: 'premium', route: 'layout.navbar' })}</a>
            </Link>
            <Link href="/templates">
              <a className="nav-item">{m({ id: 'templates', route: 'layout.navbar' })}</a>
            </Link>
            <Link href="/contact">
              <a className="nav-item">{m({ id: 'contact', route: 'layout.navbar' })}</a>
            </Link>
          </div>
          <div className="nav-links-wrap" />

          {!session && (
            <div className="nav-sign">
              <Link href="/signup">
                <a className="nav-signup-btn">
                  <button type="button" className="btn-text">
                    <Icon size={12} icon={userPlus} />{' '}
                    {m({ id: 'sign_up', route: 'layout.navbar' })}
                  </button>
                </a>
              </Link>
              <Link href="/login">
                <a>
                  <button type="button" className="btn-text">
                    <Icon size={12} icon={userO} /> {m({ id: 'log_in', route: 'layout.navbar' })}
                  </button>
                </a>
              </Link>
            </div>
          )}
          {session && (
            <div className="account-btn">
              <AccountAvatar session={session} />
            </div>
          )}
          <div className="nav-action">
            <Link href="/create">
              <a className="create-btn">
                <button type="button" className="btn-primary">
                  <Icon size={20} icon={ic_add} /> {m({ id: 'new_poll', route: 'layout.navbar' })}
                </button>
              </a>
            </Link>
            <Link href="/create">
              <a className="create-btn-wrap">
                <button type="button" className="btn-primary">
                  <Icon size={20} icon={ic_add} /> {m({ id: 'new', route: 'layout.navbar' })}
                </button>
              </a>
            </Link>
          </div>
          <div className="nav-wrap">
            <button
              type="button"
              aria-label="expand menu"
              onClick={() => setMobileMenu(!mobileMenu)}
            >
              <Icon size={24} icon={ic_menu} />
            </button>
          </div>
        </div>
        {mobileMenu && (
          <StyledMobileNav>
            <div className="nav-links">
              <Link href="/features">
                <a className="nav-item">{m({ id: 'features', route: 'layout.navbar' })}</a>
              </Link>
              <Link href="/premium">
                <a className="nav-item">{m({ id: 'premium', route: 'layout.navbar' })}</a>
              </Link>
              <Link href="/templates">
                <a className="nav-item">{m({ id: 'templates', route: 'layout.navbar' })}</a>
              </Link>
              <Link href="/contact">
                <a className="nav-item">{m({ id: 'contact', route: 'layout.navbar' })}</a>
              </Link>
            </div>
          </StyledMobileNav>
        )}
      </StyledNavbar>
    </DrawerProvider>
  );
};

export default Navbar;
